


































import Vue from 'vue'
import AuthService from '@/services/authentication.service'
import { errorMessages } from '@/utils/helpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'ActivateUser',
  components: { SubmitButton },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      showPassword: false,
      showPasswordConfirmation: false,
      token: this.$route.query.token,
    }
  },
  computed: {
    passwordFieldType(): string {
      return this.showPassword ? 'text' : 'password'
    },
    passwordFieldIcon(): string {
      return this.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    passwordConfirmationFieldType(): string {
      return this.showPasswordConfirmation ? 'text' : 'password'
    },
    passwordConfirmationFieldIcon(): string {
      return this.showPasswordConfirmation ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
  },
  methods: {
    toggleShowPassword(): void {
      this.showPassword = !this.showPassword
    },
    toggleShowPasswordConfirmation(): void {
      this.showPasswordConfirmation = !this.showPasswordConfirmation
    },
    async submit() {
      const loader = this.$loading.show()
      try {
        const response = await AuthService.activateUser(this.token.toString(), this.password, this.passwordConfirmation)
        this.$toast.success(response.data.message)
        await this.$router.push({ name: 'Login' })
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    checkToken(): void {
      if (!this.token) {
        this.$router.push({ name: 'Login' })
      } else {
        AuthService.verifyActivateUserToken(this.token.toString()).catch(() => {
          this.$router.push({ name: 'Login' })
        })
      }
    },
  },
  beforeMount() {
    this.checkToken()
  },
})
